<template>
  <partner-admin-page-card
    pageName="New Partner Group"
    iconClass="fa fa-users fa-lg"
  >
    <v-wait for="fetchingDetails">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-12 col-lg-12 mt-2">
          <b-form-group
            label="Select Institution"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-10">
                <multiselect
                  track-by="id"
                  label="display"
                  v-model="selectedInstitution"
                  :options="institutions"
                  :multiple="false"
                  :loading="isLoading"
                  :close-on-select="true"
                  placeholder="Search by FI Name, City, State or Federal Reserve ID"
                  class="simple-select form-control fi-search"
                  :showLabels="false"
                  @search-change="debouncedInstitutions"
                >
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.full_name }}, {{ props.option.city }},
                    {{ props.option.state }}
                  </template>
                </multiselect>
              </div>
            </div>
          </b-form-group>

          <br />

          <b-card class="std-border groups-card">
            <div slot="header">
              <h6 class="mb-0">
                <b>Group Users</b>
              </h6>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-5 pr-0">
                <h6>Available Users</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group v-if="!this.users.length" class="text-center">
                    <b-list-group-item>
                      <i>No Users found ...</i>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(user, index) in availableUsers" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: user.id === selectedUser.id },
                          'p-1'
                        ]"
                        @click="selectUser(user)"
                      >
                        {{ user.email }} - {{ user.first_name }}
                        {{ user.last_name }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card>
              </div>
              <div class="col-sm-2">
                <div class="group-control">
                  <div class="row text-center">
                    <div class="col-sm-12">
                      <b-button
                        type="submit"
                        size="sm"
                        variant="primary"
                        :disabled="!selectedUser.available"
                        @click="addUser()"
                        v-html="'&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                  <div class="row text-center pt-2">
                    <div class="col-sm-12">
                      <b-button
                        type="reset"
                        size="sm"
                        variant="secondary"
                        :disabled="!selectedUser.selected"
                        @click="removeUser()"
                        v-html="'&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 pl-0">
                <h6>Selected Users</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group
                    v-if="!this.selectedUsers.length"
                    class="text-center"
                  >
                    <b-list-group-item>
                      <i>No Users selected ...</i>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(user, index) in selectedUsers" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: user.id === selectedUser.id },
                          'p-1'
                        ]"
                        @click="selectUser(user)"
                      >
                        {{ user.email }} - {{ user.first_name }}
                        {{ user.last_name }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-card>
          <div slot="footer">
            <b-row class="text-center">
              <b-col md="12">
                <router-link
                  tag="button"
                  :to="{ path: '/partner_admin/groups' }"
                  class="btn btn-secondary fw-btn mt-3 mr-3 link-btn"
                  >Cancel</router-link
                >
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn btn-primary fw-btn mt-3 mx-3"
                  :disabled="!canCreate"
                  @click="createGroup"
                  >Create Group</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </v-wait>
  </partner-admin-page-card>
</template>

<script charset="utf-8">
// global
import Vue from 'vue'
// api
import partnerUsersAPI from '@/api/finapps/partner_admin/users'
import partnerGroupsAPI from '@/api/finapps/partner_admin/groups'
import fiAPI from '@/api/finapps/partner_admin/financial_institutions'
// utils
import { debounce } from 'lodash'
// ui components
import Multiselect from 'vue-multiselect'

export default {
  name: 'NewPartnerGroup',
  components: {
    Multiselect
  },
  created() {
    this.loadInitData()
    this.debouncedInstitutions = debounce(this.loadInstitutions.bind(this), 300)
  },
  data() {
    return {
      users: [],
      selectedUser: {},
      institutions: [],
      isLoading: false,
      selectedInstitution: null
    }
  },
  computed: {
    availableUsers() {
      return this.users.filter(user => user.available === true)
    },
    selectedUsers() {
      return this.users.filter(user => user.selected === true)
    },
    canCreate() {
      return this.selectedInstitution !== null
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('fetchingDetails')

      Promise.all([this.loadInstitutions(), this.fetchPartnerUsers()]).then(
        () => {
          this.$wait.end('fetchingDetails')
        }
      )
    },
    loadInstitutions(query) {
      this.isLoading = true

      return fiAPI.institutions(query).then(institutions => {
        this.prepareInstitutions(institutions)
        this.isLoading = false
      })
    },
    fetchPartnerUsers() {
      return partnerUsersAPI.all().then(res => {
        res.users.forEach(user => {
          user.available = true
          user.selected = false
        })

        this.users = res.users
      })
    },
    prepareInstitutions(institutions) {
      institutions.forEach(
        i => (i.display = `${i.full_name},${i.city},${i.state} [${i.id}]`)
      )
      this.institutions = institutions
    },
    addUser() {
      this.users.forEach(user => {
        if (user.id === this.selectedUser.id) {
          user.available = false
          user.selected = true
          this.selectedUser = {}
        }
      })
    },
    removeUser() {
      this.users.forEach(user => {
        if (user.id === this.selectedUser.id) {
          user.available = true
          user.selected = false
          this.selectedUser = {}
        }
      })
    },
    selectUser(user) {
      this.selectedUser = user
    },
    createGroup() {
      let selectedUserIDs = this.users
        .filter(user => user.selected)
        .map(user => user.id)

      partnerGroupsAPI
        .createGroup(this.selectedInstitution.id, selectedUserIDs)
        .then(res => {
          Vue.toasted.show(res.status, {
            icon: 'user-circle',
            type: 'success'
          })
          this.$router.push('/partner_admin/groups')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.groups-card {
  .card-header {
    background-color: #ebe0d3;
  }
}

.group-sub-card {
  height: 13rem;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  cursor: pointer;

  &.selected {
    background-color: #f2ece6;
  }
}
</style>

<style lang="scss">
.partner-cu2 {
  .multiselect {
    &.fi-search {
      .multiselect__single {
        font-size: 15px;
      }

      .multiselect__input {
        width: 90% !important;
      }
    }
  }
}
</style>
